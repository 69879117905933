import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    display: block;
    margin-bottom: 3rem;
  }
  a {
    display: block;
  }
`

const Error404 = () => {
  return (
    <Wrapper>
      <h1> 404 Page not found.</h1>
      <a href="/">Return Home</a>
    </Wrapper>
  )
}

export default Error404
